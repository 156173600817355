import "./ContactMe.css";
import coffee from "../../images/coffee.png";

const ContactMe = () => {

  const handleSubmit = (event) => {
    event.preventDefault();
    // Ajoutez ici le lien vers votre page Buy Me a Coffee
    const buyMeCoffeeLink = 'https://www.buymeacoffee.com/dimitriddo';
    window.open(buyMeCoffeeLink, '_blank');
  };

  return (
    <>
      <section id="contact ">
        <div className="contact-me2">
          <div className="contactme-t1 pb-3">
            <h2>Support</h2>
          </div>
          <div className="contact-me2-dec"></div>
          <div class="row align-items-center mb-5">
            <div class="col-lg-5 col-md-12">
              <div class="work-togather-text">
                <h2 class="h2-title text-white ">Buy me a coffee</h2>
                <p>
                If you are impressed and like my work, you can support me by buying me a coffee.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-7">
              <div className="about_coffee">
            <img src={coffee} alt="" />
            </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="work-togather-form-btn">
              <form onSubmit={handleSubmit}>
                <button type="submit" class="sec-btn">
                  Buy me a coffee 
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactMe;
