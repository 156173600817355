import "./AboutMe.css";
import { useGetAboutMeQuery, useGetHomeDetailsQuery } from "../../Api/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import decor1 from "../../images/decoration/dots-1.png";
import reactagle from "../../images/decoration/Rectangle-7.png";
import shady from "../../images/decoration/dots.png";


const AboutMe = () => {
  const { data: aboutData, isFetching } = useGetAboutMeQuery();
  const [aboutMe, setAboutMe] = useState(aboutData);
  const img_300 = "https://drive.google.com/uc?id=";

  const { data: conta2 } = useGetHomeDetailsQuery();
  const [contacts1Details, setContact2Details] = useState(conta2);
  const cv = contacts1Details && contacts1Details.map((data1) => data1.cv_link);
  console.log(cv);
  console.log(conta2);

  useEffect(() => {
    setAboutMe(aboutData);
    setContact2Details(conta2);
  }, [aboutData, conta2]);

  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleDownload = (cvLink) => {
    setShowModal(true);
    // Vous pouvez stocker le lien sélectionné pour téléchargement dans un état local
    // ou déclencher le téléchargement directement ici
    // window.open(cvLink, '_blank');
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {aboutMe &&
        aboutMe.map((details) => (
          <main id="about" key={details.id}>
            <div className="aboutMe-container">
              <div className="about-decor">
                <div className="about-dots">
                  <img src={decor1} alt="" />
                </div>
                <div className="about-rect">
                  <img src={reactagle} alt="" />
                </div>
                <div className="about-shady">
                  <img src={shady} alt="" />
                </div>
              </div>
              <div className="abouMe-row">
                <div
                  className=" col-lg-6 col-md-5 col-sm-12 about-img"
                  data-aos="fade-up-right"
                >
                  <img src={`../../images/contact4.png`} alt="" />
                </div>
                <div
                  className=" col-lg-6 col-md-7  col-sm-12 about_myinfo"
                  data-aos="fade-up-left"
                >
                  <div className="title">
                    <h2>{details.title}</h2>
                    <h3>{details.title_2}</h3>
                  </div>
                  <div className="about-description" style={{
    fontSize: '1.2em',
    lineHeight: '1.6',
    marginBottom: '2%',
  }}>
                    <div id="foo" unselectable="on" class="unselectable">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${details.description_one}`,
                        }}
                      />

            I am based in <a dir="ltr" href="https://maps.app.goo.gl/RDdAMMPCSUUrozBe7" target="_self">Ajaccio</a> in France, I specialize in the design and development of user interfaces.

            I build clear, attractive and functional interfaces, compliant with the latest Web standards🔥
                      
                    </div>
                  </div>

                  <div className="itscv">
                    <a
                      href="https://drive.google.com/file/d/1cRrhbwNdwe_0kX7R2StTJ_VWo39caka8/view?usp=drive_link"
                      download="English_CV.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="download-cv">
                        Download Cv <i class="bx bx-download"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ))}
    </>
  );
};

export default AboutMe;
